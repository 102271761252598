import {
    GET_USERS,
    GET_TEMPLATES
} from "../types/constant.js";

const initial_state = {
    users_list: [],
    templates: {
        hardware: [],
        software: [],
        assets: []
    }
}



export const ControlPanelReducer = (state = initial_state, action) => {
    switch (action.type) {
        case GET_USERS:
            return {
                ...state,
                users_list: action.payload ?? [],
            }
        case GET_TEMPLATES:
            return {
                ...state,
                templates: {
                    hardware: [],
                    software: [],
                    assets: [],
                    ...action.payload
                }
            }

        default:
            return state
    }
}
