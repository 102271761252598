import React, { useState, useEffect } from "react";
import {
    AppBar, Box, Toolbar, Typography, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Button,
    MenuItem, Menu, Avatar, ListItemAvatar, Slide, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, InputLabel, Select, OutlinedInput, Checkbox, Chip
} from '@mui/material'
import Table from "./Utils/Table";
import Modal from "./Utils/Modal";
import Loader from "./Utils/Loader";
import FileSaver, { saveAs } from 'file-saver';
import {
    ImportantDevices as ImportantDevicesIcon,
    Api as ApiIcon,
    Visibility as VisibilityIcon,
    WebAsset as WebAssetIcon,
    TaskAlt as TaskAltIcon,
    Edit as EditIcon,
    Delete as DeleteIcon,
    UploadFile as UploadFileIcon,
    AddBox as AddBoxIcon,
    Construction as ConstructionIcon,
    FileDownload as DownloadFileIcon,
    ExpandMore,
} from '@mui/icons-material'
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { LOGOUT_SUCCESS } from "../types/constant";
import { addAssetsActions, addHardwareActions, addSoftwareActions, approveHardwareSoftwareActions, deleteMasterDataActions, downloadStocksActions, downloadTemplatesActions, editMasterDataActions, getMasterDataActions, uploadAssetsActions, uploadHardwareActions, uploadSoftwareActions } from "../actions/ProductsActions";
import moment from "moment";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const HardwareOrSoftware = (props) => {
    return (
        <Box component="main" sx={{ display: "flex", marginTop: "75px" }}>
            <div>
                <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "left", borderWidth: 2 }}>
                    <div style={{ cursor: "pointer", marginBottom: 10 }}>
                        <Button component="label" size="small" color="primary" variant="contained" startIcon={<AddBoxIcon />} sx={{ marginLeft: 1, borderRadius: 4, paddingX: 2 }} onClick={props.addNewSystem}>
                            Add Record
                        </Button>
                        <Button component="label" size="small" color="warning" variant="contained" startIcon={<UploadFileIcon />} sx={{ marginLeft: 1, borderRadius: 4, paddingX: 2 }}>
                            Upload CSV
                            <input type="file" accept=".csv" hidden onChange={props.handleFileUpload} />
                        </Button>
                        <Button component="label" size="small" color="success" variant="contained" startIcon={<DownloadFileIcon />} sx={{ marginLeft: 1, borderRadius: 4, paddingX: 2 }} onClick={props.handleDownloadTemplate}>
                            Template
                        </Button>
                        <Button component="label" size="small" color="secondary" variant="contained" startIcon={<DownloadFileIcon />} sx={{ marginLeft: 1, borderRadius: 4, paddingX: 2 }} onClick={props.handleDownload}>
                            Download
                        </Button>
                    </div>
                </div>
                <div>
                    <Loader open={props.showLoader} />
                    <Table rows={props.rows} columns={props.columns} />
                </div>
            </div>
        </Box>
    );
};

const Home = () => {
    const drawerWidth = 200;
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const access_token_details = useSelector(state => state.token)
    const actionTypes = {
        delete: "Delete",
        action: "Renewed",
        edit: "Edit",
        view: "View"
    }
    const listTypes = {
        hardware: "Hardware - AMC details",
        software: "Software - Domain License Renewal",
        assets: "Assets"
    }
    const listTypesKeys = {
        hardware: "Hardware",
        software: "Software",
        assets: "Assets",
    }
    const ListTypesIcon = {
        hardware: <ImportantDevicesIcon />,
        software: <ApiIcon />,
        assets: <WebAssetIcon />
    }
    const [categoryType, setCategoryType] = useState(listTypesKeys.hardware);
    const [master, setMasterData] = useState([]);
    const [masterTemplate, setMasterTemplate] = useState([])
    const [requiredColumnHeader, setRequiredColumnHeader] = useState(["location", "division", "assetType", "hostName", "category",'ipAddress','make','model','serialNo','warrenty','startDate','warrentyExpire','year','month','vendoreName','amount','amountWithGST','remarks','beforeThreshold']);
    const [rowData, setRowData] = useState({});
    const [ShowncolumnsHeader, setColumnsHeader] = useState([])
    const [showLoader, setShowLoader] = useState(false)
    const [openPopup, setOpenPopup] = useState(false);
    const [edit, setEdit] = useState(false);
    const [approve, setApprove] = useState(false);
    const [deleteMode, setDelete] = useState(false);
    const [view, setView] = useState(false)
    const [addItem, setAddItem] = useState(false)
    const [highlightText, setHighLightText] = useState(listTypes.hardware)
    const [months, setMonths] = useState([])
    const [years, setYears] = useState([])
    const [errorMessage, setErrorMessage] = useState("")
    const [showDownloadConfirmation, setShowDownloadConfirmation] = useState(false);
    const [downloadHeaders, setDownloadHeaders] = React.useState([]);
    const [downloadHeadersAll, setDownloadHeadersAll] = React.useState([]);
    const [downloadHeadersKeys, setDownloadHeadersKeys] = React.useState({});
    const handleDownloadHeaderChange = (event) => {
        const {
            target: { value },
        } = event;
        setDownloadHeaders(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    useEffect(() => {
        createDownloadHeaders()
    }, [masterTemplate])
    function createDownloadHeaders() {
        let finalData = masterTemplate.reduce((acc, item) => {
            acc.list.push(item.key)
            acc.obj[item.key] = item.name
            return acc
        }, { list: [], obj: {} })
        setDownloadHeaders(finalData.list)
        setDownloadHeadersAll(finalData.list)
        setDownloadHeadersKeys(finalData.obj)
    }
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
        setShowDownloadConfirmation(false);
    };

    const [anchorE2, setAnchorE2] = React.useState(null);
    const open1 = Boolean(anchorE2);
    const handleClick1 = (event, params) => {
        setAnchorE2(event.currentTarget);
        const editRow = { ...downloadHeadersKeys }
        for (const key in params.row) {
            editRow[key] = params.row[key]
        }
        console.log('editRow', JSON.stringify(editRow));
        setRowData(editRow);
    };
    const handleClose1 = () => {
        setAnchorE2(null);
    };


    const handleActions = (actionType) => {
        setErrorMessage("")
        handleClose1()
        console.log(actionType)
        if (actionType === actionTypes.view) {
            setOpenPopup(true);
            setEdit(false);
            setDelete(false);
            setView(true)
            setApprove(false)
            setAddItem(false)
        }
        else if (actionType === actionTypes.action) {
            setOpenPopup(true);
            setEdit(false);
            setDelete(false);
            setView(false)
            setApprove(true)
            setAddItem(false)
        }
        else if (actionType === actionTypes.edit) {
            setOpenPopup(true);
            setEdit(true);
            setView(false)
            setApprove(false)
            setDelete(false);
            setAddItem(false)
        }
        else if (actionType === actionTypes.delete) {
            setOpenPopup(true);
            setEdit(false);
            setView(false)
            setApprove(false)
            setAddItem(false)
            setDelete(true);
        }
    }



    const getMasterData = async (system) => {
        let data = { type: system };
        try {
            setShowLoader(true)
            const fetchMaster = await getMasterDataActions(access_token_details.access_token, data)
            if (fetchMaster.status === 200) {
                const { master_data, template, year, month } = fetchMaster.data.data;
                setMasterData(master_data);
                setMasterTemplate(template)
                setMonths(month)
                setYears(year)
            }
            else if (fetchMaster.status === 401) {
                dispatch({ type: LOGOUT_SUCCESS, payload: {} })
                alert(fetchMaster.data.message)
                navigate('/', { replace: true })
            }
            else {
                alert(fetchMaster.data.message)
                setMasterData([]);
            }
        } catch (err) {
            console.log("Something went wrong", err)
            setMasterData([]);
        }
        finally {
            setShowLoader(false)
            setErrorMessage("")
        }
    };


    useEffect(() => {
        if (access_token_details.is_logined_in == true) {
            getMethod()
        }
        else {
            navigate('/', { replace: true })
        }
    }, [access_token_details.is_logined_in])


    const getMethod = () => {
        getMasterData(categoryType);
        formColumnsHeader(requiredColumnHeader)
    }



    const formColumnsHeader = (listOfColumnHeader) => {
        let ColumnsHeaders = []
        if (listOfColumnHeader.length !== 0) {

            ColumnsHeaders.push(
                {
                    field: "Views",
                    headerName: "",
                    sortable: false,
                    editable: false,
                    // flex: 1,
                    width: 75,
                    disableClickEventBubbling: true,
                    renderCell: (params) => {
                        return (
                            <ConstructionIcon fontSize="medium" onClick={(event) => handleClick1(event, params)} />
                        );
                    }
                }
            )

            listOfColumnHeader.map((field) => {
                var fieldHeaderName = field
                if (field == "displayName") {
                    fieldHeaderName = "name"
                }

                ColumnsHeaders.push({
                    field: field,
                    headerName: fieldHeaderName.charAt(0).toUpperCase() + fieldHeaderName.slice(1).replace(/\B([A-Z])\B/g, ' $1'),
                    sortable: false,
                    // flex: 1,
                    width: 150,
                    disableClickEventBubbling: true,
                })
            })

        }
        setColumnsHeader(ColumnsHeaders)
        return ColumnsHeaders
    }


    const rows = master;

    const updateType = (menu) => {
        console.log(menu)
        setHighLightText(menu)
        if (menu === listTypes.hardware) {
            setCategoryType(listTypesKeys.hardware);
            setRequiredColumnHeader(["location", "division", "assetType", "hostName", "category",'ipAddress','make','model','serialNo','warrenty','startDate','warrentyExpire','year','month','vendoreName','amount','amountWithGST','remarks','beforeThreshold']);
            getMasterData(listTypesKeys.hardware);
            formColumnsHeader(["location", "division", "assetType", "hostName", "category",'ipAddress','make','model','serialNo','warrenty','startDate','warrentyExpire','year','month','vendoreName','amount','amountWithGST','remarks','beforeThreshold'])
            // setHighLightText(listTypes.hardware)
        } else if (menu === listTypes.software) {
            setCategoryType(listTypesKeys.software);
            setRequiredColumnHeader(["category", "division", "description", "service", "licenseKeyOrProvider",'vendor','noOfLicense','purchaseOrRenewalDate','expiryDate','year','month','lastAMCPriceDetailsActualAmount','lastAMCPriceDetailsWithGST','remarks','beforeThreshold']);
            getMasterData(listTypesKeys.software);
            formColumnsHeader(["category", "division", "description", "service", "licenseKeyOrProvider",'vendor','noOfLicense','purchaseOrRenewalDate','expiryDate','year','month','lastAMCPriceDetailsActualAmount','lastAMCPriceDetailsWithGST','remarks','beforeThreshold'])
            // setHighLightText(listTypes.software)
        }
        else {
            setCategoryType(listTypesKeys.assets);
            getMasterData(listTypesKeys.assets);
            setRequiredColumnHeader(['location', 'division', 'workStation', 'hostName', 'critical', 'userName', 'employeeID', 'IPAddress', 'laptopDesktop', 'brand', 'serialNo', 'monitorDetails', 'monitorSN', 'mouseSN', 'KBSN', 'RAM', 'HHDSSD', 'operatingSystem', 'processorType', 'warrantyAMCEndDate', 'newReplacement', 'previousModel', 'quantity', 'PONumber', 'invoiceDate', 'approvedBy', 'vendorName', 'invoiceNumber', 'invoiceAmount', 'installationDate', 'movementDate', 'movementFrom', 'movementTo', 'movementApprovedBy', 'scrappingApprovedBy', 'scrappingDate'])
            // formColumnsHeader(['sNo', 'quantity', 'PONumber', 'date', 'approvedBy', 'vendorName', 'invoiceNumber', 'invoiceAmount', 'DCNumber', 'date1', 'brand', 'laptopDesktop', 'installationDate', 'serialNo', 'mouseSN', 'KBSN', 'monitorSN', 'monitorSize', 'RAM', 'RAMBrandWarranty', 'HDD', 'HDDBrand&Warranty', 'processorType', 'softwareConfiguration', 'assetID', 'hostName', 'IPAddress', 'location', 'division', 'userName', 'employeeID', 'assetOwner', 'warrantyAMCEndDate', 'newReplacement', 'previousModel', 'insuranceCompany', 'insuredValue', 'movementDate', 'movementFrom', 'movementTo', 'movementApprovedBy', 'scrappingApprovedBy', 'scrappingDate', 'totalCostOfAssets', 'costofPurchase', 'installationExpenses', 'scrapValue', 'transportationChargesInvolved', 'dependencyOnOtherSystems', 'collectionOfAsset', 'licenseReassignmentReclaim', 'indemnification', 'replacementEvaluation', 'disposalOrRedeployment'])
            formColumnsHeader(['location', 'division', 'workStation', 'hostName', 'critical', 'userName', 'employeeID', 'IPAddress', 'laptopDesktop', 'brand', 'serialNo', 'monitorDetails', 'monitorSN', 'mouseSN', 'KBSN', 'RAM', 'HHDSSD', 'operatingSystem', 'processorType', 'warrantyAMCEndDate', 'newReplacement', 'previousModel', 'quantity', 'PONumber', 'invoiceDate', 'approvedBy', 'vendorName', 'invoiceNumber', 'invoiceAmount', 'installationDate', 'movementDate', 'movementFrom', 'movementTo', 'movementApprovedBy', 'scrappingApprovedBy', 'scrappingDate'])
        }
    };

    const HandleSave = async () => {
        if (addItem === true) {
            const newSystemData = Object.keys(rowData).reduce((object, key) => {
                if (key !== "_id") {
                    if (masterTemplate) {
                        var isValid = false
                        masterTemplate.map((item, index) => {
                            if (item.key == key) {
                                isValid = true
                            }
                        })
                        if (isValid) {
                            object[key] = rowData[key]
                        }
                    } else {
                        object[key] = rowData[key]
                    }
                }
                return object
            }, {});
            if (categoryType === listTypesKeys.hardware) {
                try {
                    setShowLoader(true)
                    const addHardware = await addHardwareActions(access_token_details.access_token, newSystemData)
                    if (addHardware.status === 200) {
                        if (addHardware.data.success == true) {
                            setOpenPopup(false)
                            setShowLoader(false)
                            getMasterData(categoryType)
                        }
                        else {
                            setShowLoader(false)
                            setErrorMessage(addHardware.data.message)
                        }
                    } else {
                        setShowLoader(false)
                        setErrorMessage(addHardware.data.message)
                    }
                } catch (err) {
                    setShowLoader(false)
                    // console.log(err, "hardware add errr")
                    setErrorMessage(err.message)
                }
                finally {
                    setShowLoader(false)
                }

            }

            else if (categoryType === listTypesKeys.assets) {
                try {
                    setShowLoader(true)
                    const addAssets = await addAssetsActions(access_token_details.access_token, newSystemData)
                    if (addAssets.status === 200) {
                        if (addAssets.data.success == true) {
                            setOpenPopup(false)
                            setShowLoader(false)
                            getMasterData(categoryType)
                        }
                        else {
                            setShowLoader(false)
                            setErrorMessage(addAssets.data.message)
                        }
                    }
                    else {
                        setShowLoader(false)
                        setErrorMessage(addAssets.data.message)
                    }
                } catch (err) {
                    // console.log(err, "Assets add errr")
                    setShowLoader(false)
                    setErrorMessage(err.message)
                }
                finally {
                    setShowLoader(false)
                }
            }
            else {
                try {
                    setShowLoader(true)
                    const addSoftware = await addSoftwareActions(access_token_details.access_token, newSystemData)
                    if (addSoftware.status === 200) {
                        if (addSoftware.data.success == true) {
                            setOpenPopup(false)
                            setShowLoader(false)
                            getMasterData(categoryType)
                        }
                        else {
                            setShowLoader(false)
                            setErrorMessage(addSoftware.data.message)
                        }
                    } else {
                        setShowLoader(false)
                        setErrorMessage(addSoftware.data.message)
                    }
                } catch (err) {
                    // console.log(err, "software add errr")
                    setShowLoader(false)
                    setErrorMessage(err.message)
                }
                finally {
                    setShowLoader(false)
                }
            }

        } else {
            try {
                setShowLoader(true)
                const editData = await editMasterDataActions(access_token_details.access_token, rowData)
                if (editData.status === 200) {
                    if (editData.data.success == true) {
                        setOpenPopup(false)
                        setShowLoader(false)
                        getMasterData(categoryType)
                    }
                    else {
                        setShowLoader(false)
                        setErrorMessage(editData.data.message)
                    }
                } else {
                    setShowLoader(false)
                    setErrorMessage(editData.data.message)
                }
            } catch (err) {
                console.log(err, "errr in edit")
                setErrorMessage(err.message)
            }
            finally {
                setShowLoader(false)
            }

        }
    };

    const handleFileUpload = async (e) => {
        console.log('dammmmmmmmm', e.target.files)
        if (!e.target.files) {
            return;
        }
        var formData = new FormData();
        formData.append("file", e.target.files[0], e.target.files[0].name);
        console.log('Test        ', categoryType)
        if (categoryType === listTypesKeys.software) {
            let softwareRes = await uploadSoftwareActions(access_token_details.access_token, formData)
            download_log(softwareRes)
        }
        else if (categoryType === listTypesKeys.assets) {
            let assertRes = await uploadAssetsActions(access_token_details.access_token, formData)
            download_log(assertRes)
        }
        else {
            let hardwareRes = await uploadHardwareActions(access_token_details.access_token, formData)
            download_log(hardwareRes)
        }
        async function download_log(xlsxReport) {
            let isJsonType = xlsxReport.data?.type == "application/json"
            let jsonResponse = null
            if (isJsonType) {
                jsonResponse = await new Response(xlsxReport.data).json();
                alert(`${jsonResponse.message}`)
                return
            }
            if (xlsxReport.status == '200' && xlsxReport.data) {
                let fileTimeStamep = moment().format('DD-MM-YYYY HH:mm:ss')
                FileSaver.saveAs(xlsxReport.data, `${fileTimeStamep}-${categoryType}-UploadLog.xlsx`)
            }
        }
    };

    const handleDownloadTemplate = async () => {
        let fileTimeStamep = moment().format('DD-MM-YYYY HH:mm:ss')
        downloadTemplatesActions(access_token_details.access_token, categoryType)
            .then((response) => {
                saveAs(response.data, `${categoryType}InputTemplate-${fileTimeStamep}.csv`)
            })
            .catch((err) => {
                alert("Unable to download CSV Template file")
            });
    }

    const handleDownload = async () => {
        let download_data = {
            "templateType": categoryType,
            "headers": downloadHeaders
        }
        let fileTimeStamep = moment().format('DD-MM-YYYY HH:mm:ss')
        downloadStocksActions(access_token_details.access_token, download_data)
            .then((response) => {
                saveAs(response.data, `${categoryType}_${fileTimeStamep}.xlsx`)
            })
            .catch((err) => {
                alert("Unable to download report")
            });
    }

    const deleteIconClick = () => {
        setShowLoader(true)
        deleteMasterDataActions(access_token_details.access_token, { masterDataId: rowData._id, type: categoryType.toLowerCase() }).then((res) => {
            if (res.data.success == true) {
                setShowLoader(false)
                setOpenPopup(false)
                getMasterData(categoryType);
            }
            else {
                setShowLoader(false)
                alert(res.data.message)
            }
        }).catch((error) => {
            setShowLoader(false)
            alert("Delete Failed")
        })
    };

    const approveHardwareSoftware = () => {
        setShowLoader(true)
        approveHardwareSoftwareActions(access_token_details.access_token, rowData).then((res) => {
            if (res.data.success == true) {
                setOpenPopup(false)
                setShowLoader(false)
                getMasterData(categoryType);
            }
            else {
                setShowLoader(false)
                alert(res.data.message)
            }
        }).catch((error) => {
            setShowLoader(false)
            alert("Approve Failed")
        })
    }

    const addSystem = () => {
        const addNewRow = { ...downloadHeadersKeys }

        for (const key in addNewRow) {
            addNewRow[key] = ""
        }
        console.log('addNewRow', JSON.stringify(addNewRow));
        setOpenPopup(true);
        setAddItem(true)
        setView(false)
        setApprove(false)
        setRowData(addNewRow);
        setEdit(false)
        setDelete(false)
        setErrorMessage("")
    }

    const onClickControlPanel = () => {
        handleClose()
        navigate('/controlPanel')
    }

    const onClickLogout = () => {
        handleClose()
        // localStorage.removeItem("persist:ReminderManagement")
        // setIsUserAuthenticated(false)
        dispatch({ type: LOGOUT_SUCCESS, payload: {} })
        navigate('/')
    }


    return (
        <Box sx={{ display: "flex", marginBottom: '30px' }}>
            <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Remainder Management
                    </Typography>
                    <Button
                        id="basic-button"
                        variant="outlined"
                        aria-controls={'basic-menu'}
                        sx={{ textTransform: "none" }}
                        aria-haspopup="true"
                        endIcon={<ExpandMore />}
                        color='inherit'
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                    >
                        {access_token_details?.userName ?? "User Name"}
                    </Button>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        {access_token_details.role == "Admin" && <MenuItem onClick={onClickControlPanel} value={"control-panel"}>ControlPanel</MenuItem>}
                        <MenuItem onClick={onClickLogout} value={"logout"}>Logout</MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>

            <Drawer
                variant="permanent"
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: "border-box" },
                }}
            >
                <Toolbar />
                <Box sx={{ overflow: "auto" }}>
                    <List>

                        <React.Fragment>
                            {Object.entries(listTypes).map(([key, value], index) => {
                                let isSelected = highlightText === value
                                return (
                                    <ListItem
                                        disablePadding
                                        sx={isSelected ? { backgroundColor: '#1776d2' } : {}}
                                        onClick={() => {
                                            updateType(value)
                                        }}
                                    >
                                        <ListItemButton dense>
                                            <ListItemAvatar>
                                                <Avatar sx={{ width: 30, height: 30, backgroundColor: 'burlywood' }} variant="rounded" >
                                                    {ListTypesIcon[key]}
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText sx={{ color: isSelected ? 'white' : 'inherit', ml: -2 }} primary={value} />
                                        </ListItemButton>
                                    </ListItem>
                                )
                            })

                            }
                        </React.Fragment>
                    </List>
                </Box>
            </Drawer>
            <HardwareOrSoftware
                rows={rows}
                columns={ShowncolumnsHeader}
                handleFileUpload={handleFileUpload}
                addNewSystem={addSystem}
                showLoader={showLoader}
                handleDownloadTemplate={handleDownloadTemplate}
                handleDownload={() => {
                    createDownloadHeaders()
                    setShowDownloadConfirmation(true)
                }}
            />
            <Menu
                id="basic-menu"
                anchorEl={anchorE2}
                open={open1}
                onClose={handleClose1}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => { handleActions(actionTypes.view) }} value={actionTypes.view}>
                    <ListItemIcon>
                        <VisibilityIcon fontSize="medium" color="success" />
                    </ListItemIcon>
                    {actionTypes.view}
                </MenuItem>

                <MenuItem onClick={() => { handleActions(actionTypes.edit) }} value={actionTypes.edit}>
                    <ListItemIcon>
                        <EditIcon fontSize="medium" color="primary" />
                    </ListItemIcon>
                    {actionTypes.edit}
                </MenuItem>
                {highlightText !== 'Assets' &&
                    <MenuItem onClick={() => { handleActions(actionTypes.action) }} value={actionTypes.action}>
                        <ListItemIcon>
                            <TaskAltIcon fontSize="medium" color="warning" />
                        </ListItemIcon>
                        {actionTypes.action}
                    </MenuItem>
                }
                <MenuItem onClick={() => { handleActions(actionTypes.delete) }} value={actionTypes.delete}>
                    <ListItemIcon>
                        <DeleteIcon fontSize="medium" color="error" />
                    </ListItemIcon>
                    {actionTypes.delete}
                </MenuItem>
            </Menu>
            <Dialog
                open={showDownloadConfirmation} TransitionComponent={Transition} keepMounted onClose={handleClose} maxWidth={"lg"}>
                <DialogTitle>Download Master Data of <strong>{categoryType}</strong></DialogTitle>
                <DialogContent>
                    <FormControl sx={{ m: 1, width: 500 }}>
                        <InputLabel id="demo-multiple-checkbox-label">Fields to download</InputLabel>
                        <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={downloadHeaders}
                            onChange={handleDownloadHeaderChange}
                            input={<OutlinedInput label="Tag" />}
                            renderValue={(selected) => {
                                return (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((value) => {
                                            return (
                                                <Chip variant="outlined" onDelete={(val) => { console.log("Current Delete : ", val) }} key={value} label={downloadHeadersKeys[value] ?? value} />
                                            )
                                        })
                                        }
                                    </Box>
                                )
                            }}
                            MenuProps={MenuProps}
                        >
                            {downloadHeadersAll.map((name) => (
                                <MenuItem key={name} value={name}>
                                    <Checkbox checked={downloadHeaders.indexOf(name) > -1} />
                                    <ListItemText primary={downloadHeadersKeys[name] ?? name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                </DialogContent>
                <DialogActions>
                    <Button sx={{ width: 150 }} onClick={handleClose} variant="outlined" color="error">cancel</Button>
                    <Button sx={{ width: 150 }} onClick={handleDownload} variant="contained" color="primary">Download</Button>
                </DialogActions>
            </Dialog>
            {openPopup === true && (
                <Modal
                    open={openPopup}
                    view={view}
                    add={addItem}
                    makeDeleteAction={deleteIconClick}
                    editOption={edit}
                    showFields={masterTemplate}
                    deleteMode={deleteMode}
                    save={HandleSave}
                    close={setOpenPopup}
                    rows={rowData}
                    changeRowData={setRowData}
                    approve={approve}
                    handleApprove={approveHardwareSoftware}
                    months={months}
                    years={years}
                    errorMessage={errorMessage}
                />
            )}
        </Box>
    );
};

export default Home;

