import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Forms from "./Forms";
import { Typography } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Modal(props) {
  const handleClose = () => {
    props.close(!props.open);
  };
  const handleSave = () => {
    // props.close(!props.open);
    props.save();
  };
  const handleDelete = () => {
    props.makeDeleteAction()
    props.close(!props.open);

  }

  const handleChangePassword = () => {
    props.handleChangePassword()
    props.close(!props.open);
  }
  const handleApprove = () => {
    props.handleApprove()
    props.close(!props.open);
  }

  return (
    <div>
      <Dialog open={props.open} TransitionComponent={Transition} keepMounted onClose={handleClose} maxWidth={props.deleteMode === true ? "sm" : 'lg'}>
        {
          props.changePassword === true ? <DialogTitle>{'Change Password'}</DialogTitle> :
            props.deleteMode === true ? <DialogTitle>{"Are you sure you want to delete ?"}</DialogTitle> :
              props.approve === true ? <DialogTitle>{"Have you renewed this Item? You will not recieve any reminder from now."}</DialogTitle> :
                props.editOption === true ? <DialogTitle>{"Edit"}</DialogTitle> :
                  props.add === true ? <DialogTitle>{'Add'}</DialogTitle> :
                    props.view === true ? <DialogTitle>{'View'}</DialogTitle> : null

        }
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {props.deleteMode !== true && <Forms rowData={props.rows} months={props.months} years={props.years} isUser={props.isUser} changePassword={props.changePassword} change={props.changeRowData} showFields={props.showFields} editMode={props.editOption} addMode={props.add} viewMode={props.view} />}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {props.errorMessage &&
            <Typography position={'absolute'} left={20} color={'red'}>Note: {props.errorMessage ?? "Message"}</Typography>
          }
          <Button sx={{ width: 150 }} onClick={handleClose} variant="outlined" color="error">{props.view === true ? "close" : "cancel"}</Button>
          {props.deleteMode === true && props.changePassword !== true && <Button sx={{ width: 150 }} variant={'contained'} onClick={handleDelete} color="error">Delete</Button>}
          {(props.editOption === true || props.add === true) && props.changePassword !== true && <Button sx={{ width: 150 }} onClick={handleSave} variant="contained" color="success">save</Button>}
          {props.changePassword === true && <Button sx={{ width: 150 }} onClick={handleChangePassword} variant="contained" color="success">Save</Button>}
          {props.approve === true && <Button sx={{ width: 150 }} onClick={handleApprove} variant="contained" color="success">Approve</Button>}

        </DialogActions>
      </Dialog>
    </div>
  );
}
