import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import moment from "moment";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
export default function Forms(props) {
  var hasShowFields = props.showFields
  const months = props.months
  const years = props.years
  const rowData = props.rowData;
  // console.log('rowData',JSON.stringify(rowData))
  if (Object.keys(rowData).length == 0) {
    hasShowFields.map(({ key }) => {
      rowData[key] = ""
    })
  }
  var changePasswordRow = Object.keys(rowData).reduce((object, key) => {
    if (key === "id" || key === "password") {
      object[key] = rowData[key]
    }
    return object
  }, {});
  // console.log(changePasswordRow, "CHangepassword", props.changePassword)
  const viewRow = Object.keys(rowData).reduce((object, key) => {
    if (key !== "id") {
      if (hasShowFields) {
        var isValid = false
        hasShowFields.map((item, index) => {
          if (item.key === key) {
            isValid = true
          }
          return item
        })
        // console.log("isValid", isValid)
        if (isValid) {
          object[key] = rowData[key]
        }
      } else {
        object[key] = rowData[key]
      }
    }
    return object
  }, {});
  const newRow = Object.keys(rowData).reduce((object, key) => {
    if (key !== "_id") {
      if (hasShowFields) {
        var isValid = false
        hasShowFields.map((item, index) => {
          if (item.key === key) {
            isValid = true
          }
          return item
        })
        // console.log("isValid", isValid)
        if (isValid) {
          object[key] = rowData[key]
        }
      } else {
        object[key] = rowData[key]
      }
    }
    return object
  }, {});
  // console.log("newRow", JSON.stringify(newRow))
  const changeFieldValue = (key, newvalue) => {
    // console.log("ChangeFieldValue", key, newvalue)
    var newRowData = { ...rowData, [key]: newvalue }
    props.change(newRowData);
    // rowData[key] = newvalue;
    // props.change(rowData);
  };
  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { m: 1, width: "25ch" },
      }}
    // noValidate
    // autoComplete="off"
    >
      {(props.editMode === true || props.addMode === true) && (
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} columns={props.isUser ? 25 : 16}>
            {Object.entries(newRow).map(([key, value], index) => {
              var currentField = hasShowFields.filter((hsfItem, hsfIndex) => hsfItem.key === key)
              var currentValues = { key: 'year', name: 'Year', type: 'Date' }
              if (currentField.length > 0) {
                currentValues = currentField[0]
              }
              // console.log(currentValues, currentField)
              return (
                <Grid key={index} item xs={props.isUser ? 8 : 4}>
                  {(currentValues.type === "year" || currentValues.type === "month" || currentValues.type === "options") &&
                    <FormControl required={currentValues.mandatory ?? false} sx={{ m: 1, minWidth: 220 }}>
                      <InputLabel id="demo-simple-select-required-label">{currentValues.name}</InputLabel>
                      <Select
                        labelId="demo-simple-select-required-label"
                        id="demo-simple-select-required"
                        value={value}
                        label={currentValues.name}
                        onChange={(e) => changeFieldValue(key, e.target.value)}
                      >
                        {currentValues.type === "year" && years.map((yitem, yindex) => {
                          return (<MenuItem value={yitem.value}>{yitem.value}</MenuItem>)
                        })}
                        {currentValues.type === "month" && months.map((mitem, mindex) => {
                          return (<MenuItem value={mitem.value}>{mitem.value}</MenuItem>)
                        })}
                        {currentValues.type === "options" && currentValues.values.map((mitem, mindex) => {
                          return (<MenuItem value={mitem.value}>{mitem.value}</MenuItem>)
                        })}
                      </Select>
                    </FormControl>
                  }
                  {currentValues.type === "date" &&
                    <TextField
                      required={currentValues.mandatory ?? false}
                      id={key}
                      type={'date'}
                      focused
                      label={currentValues.name}
                      disabled={key === "role"}
                      defaultValue={moment(value, 'DD-MMM-YYYY').format('YYYY-MM-DD')}
                      onChange={(e) => changeFieldValue(key, moment(e.target.value).format('DD-MMM-YYYY'))}
                    />
                  }
                  {(currentValues.type === "number" || currentValues.type === "text") &&
                    <TextField
                      required={currentValues.mandatory ?? false}
                      id={key}
                      type={currentValues.type === "number" ? "number" : "text"}
                      label={currentValues.name}
                      disabled={key === "role"}
                      defaultValue={value}
                      onChange={(e) => changeFieldValue(key, e.target.value)}
                    />
                  }
                </Grid>
              );
            })}
          </Grid>
        </Box>
      )}

      {props.viewMode === true && (
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} columns={16}>
            {Object.entries(viewRow).map(([key, value], index) => {
              var currentField = hasShowFields.filter((hsfItem, hsfIndex) => hsfItem.key === key)
              var currentValues = { key: 'year', name: 'Year', type: 'Date' }
              if (currentField.length > 0) currentValues = currentField[0]
              return (
                <Grid key={index} item xs={4}>
                  <TextField
                    required={currentValues.mandatory ?? false}
                    id={key}
                    label={currentValues.name}
                    disabled={true}
                    defaultValue={value}
                    onChange={(e) => changeFieldValue(key, e.target.value)}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Box>
      )}

      {props.changePassword === true && (
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} columns={16} alignItems={'center'}>
            {Object.entries(changePasswordRow).map(([key, value], index) => {
              if (key === "password") {
                return (
                  <Grid key={index} item xs={4}>
                    <TextField
                      required
                      id={key}
                      label={
                        key
                          .replace(/([A-Z])/g, " $1")
                          .charAt(0)
                          .toUpperCase() + key.replace(/([A-Z])/g, " $1").slice(1)
                      }
                      defaultValue={props.editMode === true ? value : ""}
                      onChange={(e) => changeFieldValue(key, e.target.value)}
                    />
                  </Grid>
                );
              }
              else return <></>
            })}
          </Grid>
        </Box>
      )}

    </Box>
  );
}
