import axios from "axios";
import { config } from "../config";

const API_URL = config['api_url']

export const getUsersAction = async (token) => {
    try {
        const apiResponse = await axios.get(
            `${API_URL}/getUsers`,
            { headers: { 'Authorization': `Bearer ${token}` } }
        )
        console.log(apiResponse, 'ress')
        return apiResponse
    } catch (err) {
        return err.response
    }
}

export const createUserAction = async (token, data) => {
    try {
        const apiResponse = await axios.post(
            `${API_URL}/addUser`,
            data,
            { headers: { 'Authorization': `Bearer ${token}` } }
        )
        console.log(apiResponse, 'ress')
        return apiResponse
    } catch (err) {
        return err.response
    }
}

export const editUserAction = async (token, data) => {
    try {
        const apiResponse = await axios.post(
            `${API_URL}/users/editUser`,
            data,
            { headers: { 'Authorization': `Bearer ${token}` } }
        )
        console.log(apiResponse, 'ress')
        return apiResponse
    } catch (err) {
        return err.response
    }
}

export const deleteUserAction = async (token, data) => {
    try {
        const apiResponse = await axios.post(
            `${API_URL}/deleteUsers?id=${data.id}`,
            data,
            { headers: { 'Authorization': `Bearer ${token}` } }
        )
        console.log(apiResponse, 'ress')
        return apiResponse
    } catch (err) {
        return err.response
    }
}


export const changePasswordAction = async (token,data) => {
    try {
        const apiResponse = await axios.put(
            `${API_URL}/changePassword`,
            data,
            { headers: { 'Authorization': `Bearer ${token}` } }
        )
        console.log(apiResponse, 'ress')
        return apiResponse
    } catch (err) {
        return err.response
    }
}