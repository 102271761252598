import axios from "axios";
import { config } from "../config";

const API_URL = config['api_url']

export const getTemplatesActions = async (token, type) => {
    try {
        const apiResponse = await axios.get(
            `${API_URL}/getTemplate?type=${type}`,
            { headers: { 'Authorization': `Bearer ${token}` } }
        )
        console.log(apiResponse, 'ress')
        return apiResponse
    } catch (err) {
        return err.response
    }
}

export const updateTemplatesActions = async (token, data) => {
    try {
        const apiResponse = await axios.post(
            `${API_URL}/updateTemplate`,
            data,
            { headers: { 'Authorization': `Bearer ${token}` } }
        )
        console.log(apiResponse, 'ress')
        return apiResponse
    } catch (err) {
        return err.response
    }
}