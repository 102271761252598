import Home from '../src/components/Home.jsx';
import ControlPanel from './components/ControlPanel.jsx';
import LoginForm from './components/Login.jsx';

import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Store from "./store";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { CssBaseline } from '@mui/material';
import { useSelector } from 'react-redux';

const ThemedRouter = (props) => {
  const currentTheme = useSelector(state => state.token)
  const darkTheme = createTheme({
    palette: {
      mode: currentTheme.theme,
    },
  });
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      {props.children}
    </ThemeProvider>
  )
}


function App() {
  let persistor = persistStore(Store);
  return (
    <Provider store={Store}>
      <PersistGate persistor={persistor}>
        <ThemedRouter>
          <Router>
            <Routes>
              <Route path="/" element={<LoginForm />} />
              <Route path="/home" element={<Home />} />
              <Route path="/controlPanel" element={<ControlPanel />} />
            </Routes>
          </Router>
        </ThemedRouter>
      </PersistGate>
    </Provider>
  );
}
export default App;