import React, { useEffect, useState } from "react";
import { AppBar, Box, Button, TextField, Toolbar, Typography } from '@mui/material'
import matrix from "../Images/matrix-logo.png";
import Form from "@material-ui/core/FormControl";
import axios from "axios";
import { config } from "../config";
import LoginIcon from '@mui/icons-material/Login'
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { LOGIN_SUCCESS } from "../types/constant";
import Loader from "./Utils/Loader";
import { useSelector } from "react-redux";

const API_URL = config['api_url']


function Login() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [userEmail, setUserEmail] = useState("")
    const [userPassword, setUserPassword] = useState("")
    const [loginError, setLoginError] = useState("")
    const [showLoader, setShowLoader] = useState(false)
    const access_token_details = useSelector(state => state.token)

    const handleChangeEmailText = (inputEmail) => {
        setUserEmail(inputEmail.target.value)
        setLoginError("")
    }
    const handleChangePasswordText = (inputPassword) => {
        setUserPassword(inputPassword.target.value)
        setLoginError("")
    }

    useEffect(() => {
        if (access_token_details.is_logined_in == true) {
            navigate('/home', { replace: true })
        }
        else {
            navigate('/', { replace: true })
        }
    }, [access_token_details.is_logined_in])

    const handleLogin = async () => {
        setShowLoader(false)
        var loginData = { email: userEmail, password: userPassword }
        await axios.post(`${API_URL}/login`, loginData).then(loginResponst => {
            console.log(loginResponst)
            if (loginResponst.data.success == true || loginResponst.data.access_token) {
                setUserEmail("")
                setUserPassword("")
                setShowLoader(false)
                dispatch({ type: LOGIN_SUCCESS, payload: loginResponst.data })
                setTimeout(() => {
                    navigate('/home', { replace: true })
                }, 500);
            }
            else {
                setLoginError(loginResponst?.data?.message)
            }
        }).catch(error => {
            setShowLoader(false)
            setLoginError(error?.response?.data?.message)
        })

    }

    return (
        <Box
            textAlign="center"
            display={'flex'}
            justifyContent={'center'}
            flexDirection={'column'}
            style={{ height: "100dvh", width: "100dvw" }}
            alignItems={"center"}
        >
            <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Remainder Management
                    </Typography>
                </Toolbar>
            </AppBar>
            <Loader open={showLoader} />
            <Box style={{ alignItems: 'center' }}>
                <Form className="auth-inner" style={{ alignItems: "center", width: 400, padding: '30px', borderRadius: 10, boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px' }}>
                    <img src={matrix} width={'200px'} height={"120px"} />
                    <TextField
                        style={{ marginTop: '15px', width: '90%' }}
                        placeholder="E-mail address"
                        onChange={handleChangeEmailText}
                    />
                    <TextField
                        style={{ marginTop: '15px', marginBottom: '10px', width: '90%' }}
                        placeholder="Password"
                        type="password"
                        onChange={handleChangePasswordText}
                    />
                    {loginError !== "" &&
                        <Typography color={'red'}>{loginError + ""}</Typography>
                    }

                    <Button component="label" variant="contained" endIcon={<LoginIcon />} style={{ width: '90%', marginTop: '10px' }} size={'large'}
                        onClick={handleLogin}
                    >
                        Login
                    </Button>
                </Form>
            </Box>
        </Box>

    )
}

export default Login