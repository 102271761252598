import { TokenReducer } from "./TokenReducer"
import { ControlPanelReducer } from "./ControlPanelReducer";
import { HomeReducer } from "./HomeReducer";
import { combineReducers } from "@reduxjs/toolkit";

const RootReducers = combineReducers({
  token: TokenReducer,
  controlPanel: ControlPanelReducer,
  home: HomeReducer,
});

export default RootReducers;