export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILED = "LOGOUT_FAILED";


// Control Panel
export const THEME = "WEBSITE_THEME";
export const GET_USERS = "GET_USERS"
export const GET_TEMPLATES = "GET_TEMPLATES"


//  CASES_REDUCER_ACTIONS
export const SET_CASES_HARDWARE = "SET_CASES_HARDWARE"
export const SET_CASES_SOFTWARE = "SET_CASES_SOFTWARE"
export const SET_CASES_ASSETS = "SET_CASES_ASSETS"

// GET INDIVIDUAL CASES
export const SET_CASES_INDIVIDUAL_HARDWARE = "SET_CASES_INDIVIDUAL_HARDWARE"
export const SET_CASES_INDIVIDUAL_SOFTWARE = "SET_CASES_INDIVIDUAL_SOFTWARE"
export const SET_CASES_INDIVIDUAL_ASSETS = "SET_CASES_INDIVIDUAL_ASSETS"


