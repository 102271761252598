import React, { useState } from "react";
import {
  Box, Grid, FormControl, InputLabel, TextField, Divider,
  MenuItem, Select, Typography, FormControlLabel, Switch, Button, ListItemText, Tooltip,
} from "@mui/material";
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone';
export default function TemplateForms({
  templateType = "",
  templateData = [],
  onChangeValue = () => { },
  onAddNewValue = () => { },
  onSaveTemplate = () => { },
  onDiscardTemplate = () => { },
}) {
  const [selected, setSelected] = useState({ item: null, index: null, newOption: "" })
  const answerTypes = [
    { "key": "text", "text": "Text", "value": "text" },
    { "key": "number", "text": "Number", "value": "number" },
    { "key": "date", "text": "Date", "value": "date" },
    { "key": "month", "text": "Month", "value": "month" },
    { "key": "year", "text": "Year", "value": "year" },
    { "key": "options", "text": "Options", "value": "options" },
  ]

  const onSave = () => {
    var newtemplateData = [...templateData]
    onSaveTemplate({
      "type": templateType,
      "template": newtemplateData
    })
  }

  const changeNewOption = () => {
    let newOption = selected?.newOption ?? ""
    if (selected.newOption.trim() == "", selected.index == null || selected.item == null) {
      return
    }
    var newtemplateData = [...templateData]
    if (newtemplateData[selected.index].hasOwnProperty('values') == false) {
      newtemplateData[selected.index]['values'] = []
    }
    newtemplateData[selected.index]['values'].push({ "key": newOption, "text": newOption, "value": newOption })
    onChangeValue(newtemplateData);
    setSelected({ item: null, index: null, newOption: "" })
  }
  const changeDeleteOption = (index, optionIndex) => {
    var newtemplateData = [...templateData]
    newtemplateData[index]['values'].splice(optionIndex, 1)
    onChangeValue(newtemplateData);
    setSelected({ item: null, index: null, newOption: "" })
  }
  const changeOnDeleteQuestion = (index) => {
    var newtemplateData = [...templateData]
    newtemplateData.splice(index, 1)
    onChangeValue(newtemplateData);
  }
  const changeAnswerType = (index, type) => {
    var newtemplateData = [...templateData]
    newtemplateData[index]['type'] = type
    if (type === 'options') {
      if (newtemplateData[index].hasOwnProperty('values') == false) {
        newtemplateData[index]['values'] = []
      }
    }
    onChangeValue(newtemplateData);
  }
  const changeIsMandatory = (index, mandatory) => {
    var newtemplateData = [...templateData]
    newtemplateData[index]['mandatory'] = mandatory
    onChangeValue(newtemplateData);
  }
  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { m: 1, width: "25ch" },
        marginTop: 1
      }}
    >

      <Box sx={{ flexGrow: 1 }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', pr: 5, }}>
          <Button
            variant="contained"
            color="primary"
            sx={{ width: 200, borderRadius: 5, ml: 1 }}
            onClick={onAddNewValue}
          >
            Add New
          </Button>
          <Button
            variant="contained"
            color="warning"
            sx={{ width: 200, borderRadius: 5, ml: 1 }}
            onClick={onSave}
          >
            Update
          </Button>
          <Button
            variant="outlined"
            color="error"
            sx={{ width: 200, borderRadius: 5, ml: 1 }}
            onClick={onDiscardTemplate}
          >
            Discard
          </Button>
        </Box>
        <Grid container rowGap={4} columnGap={4} pl={2} mt={2} columns={16}>
          {templateData.map((item, index) => {
            let { key, name, type, mandatory, values } = item
            return (
              <Grid sx={{ border: 1, borderColor: 'lightgray', borderRadius: 2, pb: 2 }} key={index} item xs={7.5}>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', p: 1.5 }}>
                  <Typography variant="subtitle1"> {name} </Typography>
                  <Tooltip onClick={() => { changeOnDeleteQuestion(index) }} title="Delete Question">
                    <DeleteForeverTwoToneIcon color="error" />
                  </Tooltip>
                </Box>
                <Divider sx={{ width: '100%' }} />
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: 2 }}>
                  <FormControlLabel
                    sx={{ ml: 2, borderRadius: 2, borderWidth: 1, borderColor: 'gold' }}
                    control={<Switch checked={mandatory} />}
                    label="Mandatory"
                    labelPlacement="start"
                    onChange={(e, v) => changeIsMandatory(index, v)}
                  />
                  <FormControl sx={{ minWidth: 180, ml: 2, mt: 2 }}>
                    <InputLabel id="demo-simple-select-required-label">Answer Type:</InputLabel>
                    <Select
                      labelId="demo-simple-select-required-label"
                      id="demo-simple-select-required"
                      value={type}
                      label="Answer Type:"
                      onChange={(e) => changeAnswerType(index, e.target.value)}
                    >
                      {answerTypes.map((vitem, vindex) => {
                        return (<MenuItem key={vitem.key} value={vitem.value}>{vitem.text}</MenuItem>)
                      })}
                    </Select>
                  </FormControl>
                  {type === "options" &&
                    <FormControl sx={{ minWidth: 180, ml: 2, mt: 2 }}>
                      <InputLabel id="demo-simple-select-required-label">Options</InputLabel>
                      <Select
                        labelId="demo-simple-select-required-label"
                        id="demo-simple-select-required"
                        value={key}
                        label="Options"
                      >
                        {values.map((vitem, vindex) => {
                          return (
                            <MenuItem key={vindex} value={vitem.value}>
                              <ListItemText primary={vitem.value} />
                              <Tooltip onClick={() => { changeDeleteOption(index, vindex) }} title="Delete Option">
                                <DeleteForeverTwoToneIcon color="error" />
                              </Tooltip>
                            </MenuItem>
                          )
                        })}
                      </Select>
                    </FormControl>
                  }
                </Box>


                {type === "options" &&
                  <Box padding={1}>
                    {(selected.index != null && selected.index != null && selected.index == index) ?
                      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <TextField
                          id={key}
                          variant="outlined"
                          label='Add New Option'
                          placeholder='Add New Option'
                          onChange={(e) => { setSelected({ ...selected, newOption: e.target.value }) }}
                        />
                        <Button
                          variant="contained"
                          color="success"
                          sx={{ width: 100, borderRadius: 5, ml: 1 }}
                          onClick={changeNewOption}
                        >
                          Add
                        </Button>
                        <Button
                          variant="outlined"
                          color="error"
                          sx={{ width: 100, borderRadius: 5, ml: 1 }}
                          onClick={() => { setSelected({ item: null, index: null, newOption: "" }) }}
                        >
                          Cancel
                        </Button>
                      </Box>
                      :
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{ width: 150, borderRadius: 5, ml: 1 }}
                        onClick={() => { setSelected({ item: item, index: index, newOption: "" }) }}
                      >
                        Add New
                      </Button>
                    }

                  </Box>
                }
              </Grid>
            );
          })}
        </Grid>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', pr: 5, mt: 3 }}>
          <Button
            variant="contained"
            color="primary"
            sx={{ width: 200, borderRadius: 5, ml: 1 }}
            onClick={onAddNewValue}
          >
            Add New
          </Button>
          <Button
            variant="contained"
            color="warning"
            sx={{ width: 200, borderRadius: 5, ml: 1 }}
            onClick={onSave}
          >
            Update
          </Button>
          <Button
            variant="outlined"
            color="error"
            sx={{ width: 200, borderRadius: 5, ml: 1 }}
            onClick={onDiscardTemplate}
          >
            Discard
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
