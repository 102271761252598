import axios from 'axios';
export const setupAxios = (accessToken) => {
    axios.defaults.headers.common['Authorization'] = "Bearer " + accessToken;
}

export function getPageSize(count) {
    if (count == 0)
        return 10;
    if (count >= 50)
        return 50;
    return Math.round(count / 10) * 10
}

export function camelCase(str) {
    // Using replace method with regEx
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index == 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
}
