import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";

export default function Table(props) {
  const [pageSize, setPageSize] = useState(10)
  return (
    <div style={{ display: "flex", flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: '15px' }}>
      <div style={{ height: '79dvh', width: props.isUsersColumn ? "84dvw" : "84dvw" }}>
        <DataGrid rows={props.rows} columns={props.columns} pageSize={pageSize} onPageSizeChange={(v) => { setPageSize(v) }} rowsPerPageOptions={[10, 25, 50, 75, 100]} rowHeight={50} />
      </div>
    </div>
  );
}
