import { 
    SET_CASES_ASSETS, 
    SET_CASES_HARDWARE, 
    SET_CASES_SOFTWARE,
    SET_CASES_INDIVIDUAL_ASSETS,
    SET_CASES_INDIVIDUAL_HARDWARE,
    SET_CASES_INDIVIDUAL_SOFTWARE
 } from "../types/constant.js";

const initial_state = {
    hardware: {
        cases_list: [],
        dashboard: {},
        isUpdated: false,
        currentCase: {}
    },
    software: {
        cases_list: [],
        dashboard: {},
        isUpdated: false,
        currentCase: {}
    },
    assets: {
        cases_list: [],
        dashboard: {},
        isUpdated: false,
        currentCase: {}
    }
}


export const HomeReducer = (state = initial_state, action) => {
    switch (action.type) {
        case SET_CASES_HARDWARE:
            return {
                ...state,
                hardware: {
                    cases_list: action?.payload?.case_list ?? [],
                    dashboard: action?.payload?.dashboard ?? {},
                    isUpdated: false,
                }
            }
        case SET_CASES_SOFTWARE:
            return {
                ...state,
                software: {
                    cases_list: action?.payload?.case_list ?? [],
                    dashboard: action?.payload?.dashboard ?? {},
                    isUpdated: false,
                }
            }
        case SET_CASES_ASSETS:
            return {
                ...state,
                assets: {
                    cases_list: action?.payload?.case_list ?? [],
                    dashboard: action?.payload?.dashboard ?? {},
                    isUpdated: false,
                }
            }
        

        default:
            return state
    }
}
