import {
    LOGIN_SUCCESS,
    LOGOUT_SUCCESS,
    THEME
} from "../types/constant.js";

const initial_state = {
    theme: 'light',//dark , light
    access_token: null,
    userName:"User Name",
    is_logined_in: false,
    role: ''
};


export const TokenReducer = (state = initial_state, action) => {
    switch (action.type) {
        case THEME:
            return {
                ...state,
                theme: action.payload.theme,
            }

        case LOGIN_SUCCESS:
            return {
                ...state,
                access_token: action?.payload?.access_token ?? "",
                userName: action?.payload?.name ?? "User Name",
                email: action?.payload?.email ?? "",
                expires_in: action?.payload?.expires_in ?? "",
                role: atob(action?.payload?.role ?? ""),
                is_logined_in: true,
            }              

        case LOGOUT_SUCCESS:
            localStorage.removeItem('persist:reminderManagement');
            return {
                ...state,
                access_token: '',
                is_logined_in: false
            }

        default:
            return state
    }
}




