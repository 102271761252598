import axios from "axios";
import { config } from "../config";

const API_URL = config['api_url']

export const getMasterDataActions = async (token, data) => {
    try {
        const apiResponse = await axios.post(
            `${API_URL}/getMasterData`,
            data,
            { headers: { 'Authorization': `Bearer ${token}` } }
        )
        console.log(apiResponse, 'ress')
        return apiResponse
    } catch (err) {
        return err.response
    }
}

export const editMasterDataActions = async (token, data) => {
    try {
        const apiResponse = await axios.post(
            `${API_URL}/editMasterData`,
            data,
            { headers: { 'Authorization': `Bearer ${token}` } }
        )
        console.log(apiResponse, 'ress')
        return apiResponse
    } catch (err) {
        return err.response
    }
}

export const deleteMasterDataActions = async (token, data) => {
    try {
        const apiResponse = await axios.post(
            `${API_URL}/deleteMasterData?masterDataId=${data.masterDataId}&type=${data.type}`,
            data,
            { headers: { 'Authorization': `Bearer ${token}` } }
        )
        console.log(apiResponse, 'ress')
        return apiResponse
    } catch (err) {
        return err.response
    }
}

export const approveHardwareSoftwareActions = async (token, data) => {
    try {
        const apiResponse = await axios.put(
            `${API_URL}/action`,
            data,
            { headers: { 'Authorization': `Bearer ${token}` } }
        )
        console.log(apiResponse, 'ress')
        return apiResponse
    } catch (err) {
        return err.response
    }
}

export const addHardwareActions = async (token, data) => {
    try {
        const apiResponse = await axios.put(
            `${API_URL}/add/hardware`,
            data,
            { headers: { 'Authorization': `Bearer ${token}` } }
        )
        console.log(apiResponse, 'ress')
        return apiResponse
    } catch (err) {
        return err.response
    }
}

export const addSoftwareActions = async (token, data) => {
    try {
        const apiResponse = await axios.put(
            `${API_URL}/add/software`,
            data,
            { headers: { 'Authorization': `Bearer ${token}` } }
        )
        console.log(apiResponse, 'ress')
        return apiResponse
    } catch (err) {
        return err.response
    }
}

export const addAssetsActions = async (token, data) => {
    try {
        const apiResponse = await axios.put(
            `${API_URL}/add/assets`,
            data,
            { headers: { 'Authorization': `Bearer ${token}` } }
        )
        console.log(apiResponse, 'ress')
        return apiResponse
    } catch (err) {
        return err.response
    }
}

export const uploadHardwareActions = async (token, data) => {
    try {
        const apiResponse = await axios.post(
            `${API_URL}/upload/hardware`,
            data,
            { headers: { "content-type": "multipart/form-data", 'Authorization': `Bearer ${token}` }, responseType: 'blob' }
        )
        console.log(apiResponse, 'ress')
        return apiResponse
    } catch (err) {
        return err.response
    }
}

export const uploadSoftwareActions = async (token, data) => {
    try {
        const apiResponse = await axios.post(
            `${API_URL}/upload/software`,
            data,
            { headers: { "content-type": "multipart/form-data", 'Authorization': `Bearer ${token}` }, responseType: 'blob' }
        )
        console.log(apiResponse, 'ress')
        return apiResponse
    } catch (err) {
        return err.response
    }
}

export const uploadAssetsActions = async (token, data) => {
    try {
        const apiResponse = await axios.post(
            `${API_URL}/upload/assets`,
            data,
            { headers: { "content-type": "multipart/form-data", 'Authorization': `Bearer ${token}` }, responseType: 'blob' }
        )
        console.log(apiResponse, 'ress')
        return apiResponse
    } catch (err) {
        return err.response
    }
}

export const downloadTemplatesActions = async (token, type) => {
    try {
        const apiResponse = await axios.get(
            `${API_URL}/get/inputTemplate/${type}`,
            { headers: { 'Authorization': `Bearer ${token}` }, responseType: 'blob' }
        )
        console.log(apiResponse, 'ress')
        return apiResponse
    } catch (err) {
        return err.response
    }
}

export const downloadStocksActions = async (token, data) => {
    try {
        const apiResponse = await axios.post(
            `${API_URL}/download/masterData`,
            data,
            { headers: { 'Authorization': `Bearer ${token}` }, responseType: 'blob' }
        )
        console.log(apiResponse, 'ress')
        return apiResponse
    } catch (err) {
        return err.response
    }
}