import React, { useState, useEffect } from "react";
import {
  AppBar, Box, Toolbar, Typography, Drawer, List, ListItem, ListItemButton, ListItemText, Button, MenuItem, Menu, TextField,
  Avatar, ListItemAvatar, Tabs, Tab, Slide, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions
} from '@mui/material'
import Table from "./Utils/Table";
import Modal from "./Utils/Modal";
import Loader from "./Utils/Loader";
import PropTypes from 'prop-types';
import {
  ImportantDevices as ImportantDevicesIcon,
  Api as ApiIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  ExpandMore,
  Password,
  PersonAdd,
} from '@mui/icons-material'
import ListAltTwoToneIcon from '@mui/icons-material/ListAltTwoTone';
import BrowserUpdatedTwoToneIcon from '@mui/icons-material/BrowserUpdatedTwoTone';
import HandymanTwoToneIcon from '@mui/icons-material/HandymanTwoTone';
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { LOGOUT_SUCCESS } from "../types/constant";
import { changePasswordAction, createUserAction, deleteUserAction, editUserAction, getUsersAction } from "../actions/UsersActions";
import { getTemplatesActions, updateTemplatesActions } from "../actions/TemplatesActions";
import TemplateForms from "./Utils/Templates";
import { camelCase } from "../util";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function ControlPanel() {
  const drawerWidth = 200;
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const access_token_details = useSelector(state => state.token)
  const listTypes = {
    users: "Users",
    templates: "Templates"
  }
  const ListTypesIcon = {
    users: <ImportantDevicesIcon />,
    templates: <ApiIcon />
  }
  const [categoryType, setCategoryType] = useState("Users");
  const [highlightText, setHighLightText] = useState(listTypes.users)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    // console.log('event btn', event)
    // console.log('event btn', event.currentTarget)
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    if (access_token_details.is_logined_in == true) {
      if (access_token_details.role != "Admin") {
        navigate('/home', { replace: true })
      }
      console.log("Access token Control Panel: ", access_token_details)
    }
    else {
      navigate('/', { replace: true })
    }
  }, [access_token_details.is_logined_in])

  const onClickLogout = () => {
    handleClose()
    dispatch({ type: LOGOUT_SUCCESS, payload: {} })
    navigate('/')
  }
  const onClickHome = () => {
    handleClose()
    navigate('/home')
  }

  const updateType = (menu) => {
    console.log(menu)
    setHighLightText(menu)
    if (menu === listTypes.users) {
      setCategoryType(listTypes.users);
    } else if (menu === listTypes.templates) {
      setCategoryType(listTypes.templates);
    }
  };


  return (
    <Box sx={{ display: "flex", marginBottom: '30px' }}>
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Remainder Management
          </Typography>
          <Button
            id="basic-button"
            variant="outlined"
            aria-controls={'basic-menu'}
            sx={{ textTransform: "none" }}
            aria-haspopup="true"
            endIcon={<ExpandMore />}
            color='inherit'
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            {access_token_details?.userName ?? "User Name"}
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={onClickHome} value={"home"}>Home</MenuItem>
            <MenuItem onClick={onClickLogout} value={"logout"}>Logout</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: "border-box" },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: "auto" }}>
          <List>

            <React.Fragment>
              {Object.entries(listTypes).map(([key, value], index) => {
                let isSelected = highlightText === value
                return (
                  <ListItem
                    disablePadding
                    sx={isSelected ? { backgroundColor: '#1776d2' } : {}}
                    onClick={() => {
                      updateType(value)
                    }}
                  >
                    <ListItemButton dense>
                      <ListItemAvatar>
                        <Avatar sx={{ width: 30, height: 30, backgroundColor: 'burlywood' }} variant="rounded" >
                          {ListTypesIcon[key]}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText sx={{ color: isSelected ? 'white' : 'inherit', ml: -2 }} primary={value} />
                    </ListItemButton>
                  </ListItem>
                )
              })

              }
            </React.Fragment>
          </List>
        </Box>
      </Drawer>
      {access_token_details.role == "Admin" ?
        <>
          {categoryType == listTypes.users &&
            <UsersControl access_token_details={access_token_details} />
          }

          {categoryType == listTypes.templates &&
            <TemplatesControl access_token_details={access_token_details} />
          }
        </>
        :
        <Typography >Access Denied</Typography>
      }
    </Box>
  )
}

export default ControlPanel

function UsersControl({ access_token_details }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [requiredColumnHeader, setRequiredColumnHeader] = useState(["displayName", "role", "email", "mobileNo"]);
  const [rowData, setRowData] = useState({});
  const [ShowncolumnsHeader, setColumnsHeader] = useState([])
  const [showLoader, setShowLoader] = useState(false)
  const [openPopup, setOpenPopup] = useState(false);
  const [edit, setEdit] = useState(false);
  const [deleteMode, setDelete] = useState(false);
  const [view, setView] = useState(false)
  const [addItem, setAddItem] = useState(false)
  const [users, setUsers] = useState([]);
  const [changePassword, setChangePassword] = useState(false)
  useEffect(() => {
    if (access_token_details.is_logined_in == true) {
      getUsersMethod()
    }
  }, [access_token_details.is_logined_in])
  const addUser = () => {
    const addNewRow = { ...users[0] }
    for (const key in addNewRow) {

      if (key == "role") addNewRow[key] = "User"
      else addNewRow[key] = ""
    }
    // console.log(addNewRow, "add")

    setOpenPopup(true);
    setAddItem(true)
    setRowData(addNewRow);
    setEdit(false)
    setDelete(false)
  }

  const deleteUserClick = () => {
    setShowLoader(true)
    deleteUserAction(access_token_details.access_token, { id: rowData._id }).then((res) => {
      if (res.data.success == true) {
        setShowLoader(false)
        setOpenPopup(false)
        getUsersMethod()
      }
      else {
        setShowLoader(false)
        alert(res.data.message)
      }
    }).catch((err) => {
      setShowLoader(false)
      alert(err.data.message)
    }).finally(() => {
      setShowLoader(false)
    })
  }

  const handleChangePassword = async () => {
    try {
      const userChangePasswordData = {
        id: rowData['_id'],
        password: rowData['password']
      }
      setShowLoader(true)
      const updateUserPassword = await changePasswordAction(access_token_details.access_token, userChangePasswordData)
      if (updateUserPassword.status === 200) {
        if (updateUserPassword.data.success == true) {
          setShowLoader(false)
          setOpenPopup(false)
          getUsersMethod()
        } else {
          setShowLoader(false)
          alert(updateUserPassword.data.message)
        }
      }
    } catch (err) {
      setShowLoader(false)
      console.log(err, "Update User Password errr")
    }
  }
  const getUsersMethod = async () => {
    try {
      setShowLoader(true)
      const userList = await getUsersAction(access_token_details.access_token)
      if (userList.status === 200) {
        const userListData = userList.data;
        setUsers(userListData);
      }
      else if (userList.status === 401) {
        dispatch({ type: LOGOUT_SUCCESS, payload: {} })
        alert(userList.data.message)
        navigate('/', { replace: true })
      }
      else {
        alert(userList.data.message)
        setUsers([]);
      }
    } catch (err) {
      setUsers([]);
    }
    finally {
      setShowLoader(false)
      formColumnsHeader(requiredColumnHeader)
    }
  }
  const HandleSaveUser = async () => {
    if (addItem === true) {
      try {
        const newUserData = Object.keys(rowData).reduce((object, key) => {
          if (key !== '_id' && key !== 'id') {
            if (key == "mobileNumber") object['mobileNo'] = rowData['mobileNumber']
            else object[key] = rowData[key]
          }
          return object
        }, {})
        const createUser = await createUserAction(access_token_details.access_token, newUserData)
        if (createUser.status === 200) {
          if (createUser.data.success == true) {
            setOpenPopup(false)
            getUsersMethod()
          }
          else {
            alert(createUser.data.message)
          }
        }
      } catch (err) {
        console.log(err, "Create User Error")
      }
    }
    else {
      try {
        const editUserData = Object.keys(rowData).reduce((object, key) => {
          if (key !== 'id') {
            if (key == "mobileNumber") object['mobileNo'] = rowData['mobileNumber']
            else object[key] = rowData[key]
          }
          return object
        }, {})
        const updateUser = await editUserAction(access_token_details.access_token, editUserData)
        if (updateUser.data.success == true) {
          setOpenPopup(false)
        }
        else {
          alert(updateUser.data.message)
        }
      } catch (err) {
        console.log(err, "Update User errr")
      }

    }
  }
  const formColumnsHeader = (listOfColumnHeader) => {
    let ColumnsHeaders = []
    if (listOfColumnHeader.length !== 0) {

      listOfColumnHeader.map((field) => {
        var fieldHeaderName = field
        if (field == "displayName") {
          fieldHeaderName = "name"
        }
        ColumnsHeaders.push({
          field: field,
          headerName: fieldHeaderName.charAt(0).toUpperCase() + fieldHeaderName.slice(1).replace(/\B([A-Z])\B/g, ' $1'),
          sortable: false,
          flex: 1,
          width: 100,
          disableClickEventBubbling: true,
        },
        )
      })

      ColumnsHeaders.push(
        {
          field: "Change Password",
          headerName: "Change Password",
          sortable: false,
          editable: false,
          flex: 1,
          disableClickEventBubbling: true,

          renderCell: (params) => {
            return (
              <Password
                onClick={() => {
                  setRowData({ ...params.row, password: "" });
                  setOpenPopup(true);
                  setEdit(false);
                  setView(false)
                  setAddItem(false)
                  setDelete(false);
                  setChangePassword(true)
                }}
              />
            );
          },
        },
        {
          field: "Edit",
          headerName: "Edit",
          sortable: false,
          editable: false,
          flex: 1,
          disableClickEventBubbling: true,

          renderCell: (params) => {
            return (
              <EditIcon
                onClick={() => {
                  setRowData({ ...params.row });
                  setOpenPopup(true);
                  setEdit(true);
                  setView(false)
                  setDelete(false);
                  setChangePassword(false)
                  setAddItem(false)

                }}
              />
            );
          },
        },
        {
          field: "Delete ",
          headerName: "Delete",
          sortable: false,
          editable: false,
          flex: 1,
          disableClickEventBubbling: true,

          renderCell: (params) => {
            return (
              <DeleteIcon
                onClick={() => {
                  setRowData({ ...params.row });
                  setOpenPopup(true);
                  setEdit(false);
                  setView(false)
                  setAddItem(false)
                  setChangePassword(false)
                  setDelete(true);
                }}
              />
            );
          },
        }
      )
    }
    setColumnsHeader(ColumnsHeaders)
    return ColumnsHeaders
  }
  return (
    <Box sx={{ display: "flex", marginBottom: '30px' }}>
      <Box component="main" sx={{ display: "flex", flexDirection: 'column', marginTop: "75px" }}>
        <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "left", borderWidth: 2 }}>
          <div style={{ cursor: "pointer", marginBottom: 10 }}>
            <Button component="label" variant="outlined" startIcon={<PersonAdd />} onClick={addUser}>
              Add User
            </Button>
          </div>
        </div>
        <div>
          <Loader open={showLoader} />
          <Table rows={users} columns={ShowncolumnsHeader} isUsersColumn={true} />
        </div>
      </Box>
      {openPopup === true && (
        <Modal
          open={openPopup}
          view={view}
          add={addItem}
          isUser={true}
          changePassword={changePassword}
          handleChangePassword={handleChangePassword}
          showFields={[
            { "key": "displayName", "name": "User Name", "type": "text" },
            { "key": "email", "name": "Email Address", "type": "text" },
            { "key": "mobileNo", "name": "Mobile Number", "type": "text" }
          ]}
          makeDeleteAction={deleteUserClick}
          editOption={edit}
          deleteMode={deleteMode}
          save={HandleSaveUser}
          close={setOpenPopup}
          rows={rowData}
          changeRowData={setRowData}
        />
      )}
    </Box>
  )
}

function TemplatesControl({ access_token_details }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const listTypes = {
    hardware: "hardware",
    software: "software",
    assets: "assets",
  }
  const listTypesKey = {
    0: "hardware",
    1: "software",
    2: "assets",
  }
  const [showLoader, setShowLoader] = useState(false)
  const [saveConfirmation, setSaveConfirmation] = useState(false)
  const [selectedTab, setSelectedTab] = useState(0);
  const [templateType, setTemplateType] = useState(listTypes.hardware)
  const [templateList, setTeplateList] = useState([])
  const [currentTemplate, setCurrentTemplate] = useState(null)
  const [showAddNew, setShowAddNew] = useState(false)
  const [newQuestionName, setNewQuestionName] = useState("")
  const [newQuestionError, setNewQuestionError] = useState("")
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    setTemplateType(listTypesKey[newValue])
  }
  useEffect(() => {
    if (access_token_details.is_logined_in == true) {
      console.log("Access token at Templates: " + access_token_details)
    }
  }, [access_token_details.is_logined_in])
  const getTemplates = async () => {
    try {
      setShowLoader(true)
      let templatesRes = await getTemplatesActions(access_token_details.access_token, templateType)
      console.log(templatesRes)
      if (templatesRes.status === 200) {
        const data = templatesRes.data.data;
        setTeplateList(data)
      }
      else if (templatesRes.status === 401) {
        dispatch({ type: LOGOUT_SUCCESS, payload: {} })
        alert(templatesRes.data.message)
        navigate('/', { replace: true })
      }
      else {
        alert(templatesRes.data.message)
        setTeplateList([]);
      }
    } catch (err) {
      console.log("Something went wrong", err)
      setTeplateList([]);
    }
    finally {
      setShowLoader(false)
      setSaveConfirmation(false)
    }
  }

  const saveTemplateConfirmation = (data) => {
    console.log(data)
    setCurrentTemplate(data)
    setSaveConfirmation(true)
  }

  const onDiscardTemplate = () => {
    getTemplates()
  }

  const handleClose = () => {
    setSaveConfirmation(false)
    setShowAddNew(false)
  }

  const handleAddNewQuestion = () => {
    var newtemplateData = [...templateList]
    let newTemp = {
      'key': camelCase(newQuestionName),
      'mandatory': true,
      'name': newQuestionName,
      'type': "text"
    }
    let filterData = newtemplateData.filter(item => item.key === newTemp.key)
    console.log(filterData)
    if (filterData.length == 0) {
      newtemplateData.push(newTemp)
      setTeplateList(newtemplateData)
      handleClose()
    }
    else {
      setNewQuestionError("already exists key:" + filterData[0]?.['key'] + "- name:" + filterData[0]?.['name'])
    }
  }

  const handleAddNewQuestionConfirm = () => {
    setShowAddNew(true)
    setNewQuestionError("")
    setNewQuestionName("")
  }

  const saveUpdateTemplate = async () => {
    try {
      setShowLoader(true)
      let templatesRes = await updateTemplatesActions(access_token_details.access_token, currentTemplate)
      console.log(templatesRes)
      if (templatesRes.status === 200) {
        alert(templatesRes.data.message)
        getTemplates()
      }
      else if (templatesRes.status === 401) {
        dispatch({ type: LOGOUT_SUCCESS, payload: {} })
        alert(templatesRes.data.message)
        navigate('/', { replace: true })
      }
      else {
        alert(templatesRes.data.message)
        setTeplateList([]);
      }
    } catch (err) {
      console.log("Something went wrong", err)
      setTeplateList([]);
    }
    finally {
      setShowLoader(false)
      setSaveConfirmation(false)
    }
  }

  useEffect(() => {
    getTemplates()
  }, [templateType])

  return (
    <Box component="main" sx={{ display: "flex", flexDirection: 'column', marginTop: "55px" }}>
      <Loader open={showLoader} />
      <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
        <Tabs value={selectedTab} onChange={handleTabChange} sx={{ height: 55 }} aria-label="basic tabs example">
          <Tab label="Hardware" icon={<HandymanTwoToneIcon />} iconPosition="start" {...a11yProps(0)} />
          <Tab label="Software" icon={<ListAltTwoToneIcon />} iconPosition="start" {...a11yProps(1)} />
          <Tab label="Assets" icon={<BrowserUpdatedTwoToneIcon />} iconPosition="start" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={selectedTab} index={0}>
        <TemplateForms
          templateType={templateType}
          templateData={templateList}
          onChangeValue={setTeplateList}
          onSaveTemplate={saveTemplateConfirmation}
          onDiscardTemplate={onDiscardTemplate}
          onAddNewValue={handleAddNewQuestionConfirm}
        />
      </TabPanel>
      <TabPanel value={selectedTab} index={1}>
        <TemplateForms
          templateType={templateType}
          templateData={templateList}
          onChangeValue={setTeplateList}
          onSaveTemplate={saveTemplateConfirmation}
          onDiscardTemplate={onDiscardTemplate}
          onAddNewValue={handleAddNewQuestionConfirm}
        />
      </TabPanel>
      <TabPanel value={selectedTab} index={2}>
        <TemplateForms
          templateType={templateType}
          templateData={templateList}
          onChangeValue={setTeplateList}
          onSaveTemplate={saveTemplateConfirmation}
          onDiscardTemplate={onDiscardTemplate}
          onAddNewValue={handleAddNewQuestionConfirm}
        />
      </TabPanel>
      <Dialog
        open={showAddNew} TransitionComponent={Transition} keepMounted onClose={handleClose} maxWidth={"lg"}>
        <DialogTitle>Add New Field </DialogTitle>
        <DialogContent>
          <TextField
            label="Add New Field"
            fullWidth
            type="text"
            sx={{ mt: 3 }}
            value={newQuestionName}
            onChange={(e) => { setNewQuestionName(e.target.value) }}
          />
          {newQuestionError.length > 0 &&
            <Typography color={'red'} >Note: {newQuestionError ?? ""}</Typography>
          }
        </DialogContent>
        <DialogActions>
          <Button sx={{ width: 150 }} onClick={handleClose} variant="outlined" color="error">cancel</Button>
          <Button sx={{ width: 150 }} onClick={handleAddNewQuestion} variant="contained" color="primary">Add</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={saveConfirmation} TransitionComponent={Transition} keepMounted onClose={handleClose} maxWidth={"sm"}>
        <DialogTitle>Update Template</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to update template of <strong>{templateType.toUpperCase()}</strong>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button sx={{ width: 150 }} onClick={handleClose} variant="outlined" color="error">cancel</Button>
          <Button sx={{ width: 150 }} onClick={saveUpdateTemplate} variant="contained" color="warning">Update</Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}